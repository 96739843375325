$wheelBox-width: 600px;
.bodyBox {
    position:relative;
    width: 100%;
    height: $wheelBox-width;
    overflow: hidden;
    background: white url($images-path + '/sfondo.jpg') right bottom no-repeat;
    background-size: cover;
    @include media-breakpoint-down(md) {
        background-position: center bottom;
        height: auto;
    }
    @import "wheel";
    @import "form";
    &__list {
        position: absolute;
        bottom: 25px;
        right: 30px;
        @include media-breakpoint-only(lg) {
            bottom: 5px;
            right: 15px;
        }
        list-style: none;
        margin: 0;
        padding: 0;
        @extend .clearfix;
        @include media-breakpoint-down(md) {
            position: static;
            bottom: 0;
            right: 0;
            @include rem(margin-top, 30px);
        }
        li {
            float: left;
            list-style: none;
            margin: 0;
            padding: 0;
            @include rem(padding, 0 0 0 15px);
            color: white;
            @include media-breakpoint-down(md) {
                float: none;
                @include rem(padding, 0 0 15px 15px);
            }
            a {
                color: white;
                font-family: $font-family-bookCondensed;
                text-transform: uppercase;
                @include rem((
                        font-size: 16px,
                        line-height: 22px
                ));
                @include media-breakpoint-only(lg) {
                    @include rem((
                            font-size: 14px,
                            line-height: 22px
                    ));
                }
            }
            &:before {
                content: "> ";
            }
            &:first-child {
                @include rem(padding-left, 0);
                @include media-breakpoint-down(md) {
                    @include rem(padding, 0 0 15px 15px);
                }
            }
        }
    }
    .test_aperturamodali {
        position: absolute;
        bottom: 0;
        right: 15px;
        @include media-breakpoint-only(lg) {
            bottom: 30px;
            right: 15px;
        }
        list-style: none;
        margin: 0;
        padding: 0;
        @extend .clearfix;
        @include media-breakpoint-down(md) {
            position: static;
            bottom: 0;
            right: 0;
            @include rem(margin-top, 30px);
        }
        li {
            float: left;
            list-style: none;
            margin: 0;
            padding: 0;
            @include rem(padding, 0 0 0 15px);
            color: white;
            @include media-breakpoint-down(md) {
                float: none;
                @include rem(padding, 0 0 15px 15px);
            }
            a {
                color: white;
                font-family: $font-family-bookCondensed;
                text-transform: uppercase;
                @include rem((
                        font-size: 12px,
                        line-height: 14px
                ));
            }
            &:before {
                content: "> ";
            }
            &:first-child {
                @include rem(padding-left, 0);
                @include media-breakpoint-down(md) {
                    @include rem(padding, 0 0 15px 15px);
                }
            }
        }
    }
}