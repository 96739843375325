$wheelBox-width: 600px !default;
.bodyBox__form {
    float: left;
    position: relative;
    // padding del parent di 30px (15px left e 15px right)
    $marginTot: 60px;
    @include rem((
            margin-left: 15px,
            margin-right: 15px,
            padding: 30px 30px 60px 30px
    ));
    width: calc(100% - ((#{$wheelBox-width} + 30px) + #{$marginTot}));

    background-color: rgba(0, 0, 0, 0.49);
    @include media-breakpoint-up(lg) {
        right: 15px;
        @include vertical-align();
    }
    @include media-breakpoint-only(lg) {
        width: calc(100% - ((#{$wheelBox-width} - 20px) + #{$marginTot}));
    }
    @include media-breakpoint-down(md) {
        margin-top: 20px;
        width: calc(100% - 30px);
        float: none;
        @include rem((
                padding-left: 10px,
                padding-right: 10px
        ));
    }
    h1 {
        font-family: $font-family-demiCondensed;
        font-weight: normal;
        color: white;
        text-transform: uppercase;
        width: 80%;
        @include rem((
                margin: 0 0 30px 0,
                font-size: 24px,
                line-height: 28px
        ));
        @include media-breakpoint-down(lg) {
            @include rem((
                    font-size: 28px,
                    line-height: 32px
            ));
        }
    }
}

.formQQM {
    .lbl_mob {
        display: block;
        font-family: $font-family-bookCondensed;
        margin: 0;
        color: white;
        @include rem((
                padding: 0 15px,
                font-size: 20px
        ));
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
    .form-group {
        margin-bottom: 0;
        @include media-breakpoint-up(lg) {
            @include rem((
                    margin-bottom: 15px
            ));
        }
        .formQQM__error {
            visibility: hidden;
            color: $red;
            font-family: $font-family-bookCondensed;
            min-height: 26px;
            @include rem((
                    font-size: 14px,
                    line-height: 16px,
                    padding-top:5px,
                    padding-bottom:5px
            ));
            label {
                margin-bottom: 0;
            }
        }
        &.has-danger {
            .formQQM__error {
                visibility: visible;
                color: $red;
                font-family: $font-family-bookCondensed;
                min-height: 26px;
                @include rem((
                        font-size: 14px,
                        line-height: 16px,
                        padding-top:5px,
                        padding-bottom:5px
                ));
            }
        }
    }
    &__input {
        padding: 0 0.75rem;
        height: 53px;
        line-height: 53px;
        //border: 1px solid white;
        border-radius: 0;
        color: $gray-dark;
        font-family: $font-family-bookCondensed;
        @include rem((
            //margin-top: 15px,
                font-size: 20px
        ));
        @include media-breakpoint-only(lg) {
            @include rem((
                    font-size: 18px
            ))
        }
        @include media-breakpoint-down(md) {
            height: 40px;
            line-height: 40px;
            font-size: 1rem;
        }
        &::-webkit-input-placeholder {
        }
        /* safari - chrome */
        &::-moz-placeholder {
        }
        /* firefox 19+ */
        &:-ms-input-placeholder {
        }
        /* ie */
    }
    &__privacy {
        position: absolute;
        bottom: 15px;
        right: 30px;
        a {
            color: white;
            font-family: $font-family-bookCondensed;
            @include rem((
                    font-size: 15px,
                    line-height: 15px
            ));
        }
    }
    .btn {
        padding: 0;
        text-align: center;
        width: 100%;
        height: 65px;
        line-height: 65px;
        border: 0 none;
        border-radius: 0;
        color: white;
        font-family: $font-family-demiCondensed;
        background-color: $red;
        text-transform: uppercase;
        @include rem((
                margin-top: 15px,
                font-size: 30px
        ));
        @include media-breakpoint-down(md) {
            height: 50px;
            line-height: 50px;
            @include rem((
                    margin-top: 10px,
                    font-size: 26px
            ))
        }
    }
}

// Bug fix iOS
input[type='date'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    height: 40px;
    line-height: 40px;
}
