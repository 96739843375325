::selection {
    background: #333;
    color: #fff;
    text-shadow: none;
}

::-moz-selection {
    background: #333;
    color: #fff;
    text-shadow: none;
}

.modal {
    .modal-dialog {
        margin: 15% auto;
        .modal-content {
            border-radius:0;
            box-shadow:inset 0 0 0 rgba(0,0,0,0),0 0 0 rgba(0,0,0,0);
            border: 0;
            .modal-header {
                border-bottom: 0;
                .close {
                    opacity:1;
                    width:30px;
                    height:30px;
                    margin-right: 14px;
                    background:url($images-path + '/icon-close-modal.png') top center no-repeat;
                    &:focus {
                        outline: none;
                    }
                    @include media-breakpoint-down(sm) {
                        margin-right: 7px;
                    }
                }
                .modal-title {
                    padding-left: 15px;
                    font-family: $font-family-demiCondensed;
                    font-size: 24px;
                    color: $gray-dark;
                    line-height: 30px;
                }
            }
            .modal-body{
                font-family: $font-family-bookCondensed;
                font-size: 18px;
                .mps-modalForm {
                    margin: 20px 0;
                    .form-group {
                        margin-left: 0;
                        margin-right: 0;
                        padding-left: 0;
                        padding-right: 0;
                        input[type='text'].form-control {
                            height: 55px;
                            font-family: $font-family-bookCondensed;
                            font-size: 18px;
                            line-height: 55px;
                            color: $gray-dark;
                            padding: 0 12px;
                            border-radius:0;
                            &:focus {
                                border-color: #333;
                                box-shadow:inset 0 0 0 rgba(0,0,0,0),0 0 0 rgba(0,0,0,0);
                            }
                            &.error {
                                border: 2px solid $red;
                            }
                        }
                        // ERROR-LABEL
                        .mpsModal-label-error {
                            label.error {
                                margin:0;
                                &:before {
                                    content:'\00a0';
                                }
                            }
                            color: $red;
                            display: block;
                            font-family: $font-family-bookCondensed;
                            font-weight:300;
                            font-size: 14px;
                            line-height:14px;
                            height: 14px;
                            margin-top:8px;
                        }
                    }
                    .btn {
                        background-color: $blue;
                        margin:0;
                        @include border-radius(0);
                        border: 0 none;
                        padding:0;
                        font-family: $font-family-demiCondensed;
                        font-size: 18px;
                        line-height: 50px;
                        color: white;
                        //width: calc(100% - 30px);
                        height:50px;
                        width: 100%;
                        @include media-breakpoint-down(sm) {
                            font-size: 16px;
                        }
                    }
                }
            }
            .modal-footer {
                text-align: center;
                padding-top: 0;
                border-top: 0;
                .btn {
                    background-color: $blue;
                    margin:0 15px;
                    @include border-radius(0);
                    border: 0 none;
                    padding:0;
                    font-family: $font-family-demiCondensed;
                    font-size: 18px;
                    line-height: 50px;
                    color: white;
                    width: calc(100% - 30px);
                    height:50px;
                }
            }
        }
    }
    &#modalError {
        .modal-body{
            font-size: 18px;
        }
        .modal-footer {
            .container-fluid {
                padding:0;
            }
            .btn {
                width:100%;
                margin:0 0 0 0;
                @include media-breakpoint-down(sm) {
                    margin-bottom: 10px;

                }
            }
        }
    }
}