.footer {
    position: relative;
    padding: 0;
    @include media-breakpoint-down(md) {
        padding: 0;
    }
    @include media-breakpoint-up(lg) {
        background-color: $gray-lighter;
    }
    &__rowContainer {
        position: relative;
        @include media-breakpoint-down(lg) {
            @include rem(padding-bottom, 25px);
        }
        h3 {
            @include rem(margin-top, 28px);
            text-transform: uppercase;
            @include rem ((font-size: 30px, line-height: 40px));
            font-family: $font-family-demiCondensed;
            @include media-breakpoint-down(lg) {
                @include rem ((font-size: 26px, line-height: 30px));
            }
        }
        p {
            @include rem ((font-size: 24px, line-height: 30px));
        }
        &--mobile {
            text-align: center;
            background-color: $gray-lighter;
            margin-bottom: 25px;
            padding-top: 20px;
            h3 {
                text-transform: uppercase;
                font-weight: bold;
            }
            p {
                font-size: 20px;
                line-height: 24px;
            }
        }
    }
    .footer__rx {
        @include rem(margin-top, 2px);
        @include media-breakpoint-only(lg) {
            @include rem(margin-top, 16px);
        }
        @include media-breakpoint-down(md) {
            background-color: $gray-lighter;
            @include rem((
                    margin-top: 25px,
                    padding-bottom: 15px
            ));
            text-align: center;
        }
        &__bolli {
            float: left;
            @include media-breakpoint-down(md) {
                float: none;
            }
        }
        p {
            @include rem((
                    margin: 0,
                    font-size: 18px,
                    line-height: 22px
            ));
            @include media-breakpoint-up(xl) {
                @include rem((
                        margin: 20px 0
                ));
            }
        }
    }
    .footer__lx {
        img {
            display: none;
        }
        h3 {
            font-family: $font-family-demiCondensed;
            font-weight: normal;
            text-transform: uppercase;
            @include rem((
                    margin: 15px 0 15px 220px,
                    font-size: 24px,
                    line-height: 28px
            ));
            @include media-breakpoint-down(md) {
                @include rem((
                        margin: 15px 0 15px 0
                ));
            }
            @include media-breakpoint-up(xl) {
                @include rem((
                        margin-top: 20px
                ));
            }
        }
        p {
            font-family: $font-family-bookCondensed;
            @include rem((
                    margin: 5px 0 0 220px,
                    font-size: 18px,
                    line-height: 22px
            ));
            @include media-breakpoint-down(md) {
                @include rem((
                        margin: 5px 0 0 0
                ));
            }
        }
        @include media-breakpoint-down(md) {
            background-color: $gray-lighter;
            //@include rem(margin-top, 25px);
            text-align: center;
            img {
                display: block;
                @include rem(margin, 25px auto 0 auto);
            }
            h3, p {
                @include rem((
                        padding-left: 15px,
                        padding-right: 15px
                ));
            }
        }
    }
    .footer__mobile {
        font-family: $font-family-bookCondensed;
        @include rem((
                padding: 0 0 15px 0,
                font-size: 18px,
                line-height: 22px
        ));
        p {
            @include rem((margin-bottom, 10px));
            text-align: center;
        }
        &__cta {
            display: block;
            width: calc(100% - 30px);
            height: 40px;
            line-height: 40px;
            background-color: $red;
            color: white;
            font-family: $font-family-demiCondensed;
            font-size: 26px;
            text-transform: uppercase;
            margin: 0 auto;
            text-align: center;
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
        }

    }
    .footer__disclaimer {
        background-color: white;
        @include media-breakpoint-up(lg) {
            box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.5);
        }
        @include rem((
                padding: 20px 15px,
                font-size: 13px,
                line-height: 16px
        ));
        font-style: italic;
        p {
            margin: 0;
            padding: 0;
            a {
                text-decoration: underline;
                color: $gray-dark;
            }
        }
    }
    .footer__bottom {
        @include rem((
                font-size: 13px,
                line-height: 25px
        ));
        text-align: right;
        border-top: 1px solid #CCCCCC;
        background-color: white;
        @include media-breakpoint-down(md) {
            text-align: center;
        }
        @include media-breakpoint-down(xs) {
            @include rem((
                    font-size: 11px,
                    line-height: 25px
            ));
        }
        ul {
            float: right;
            list-style: none;
            padding: 0;
            margin: 0;
            @extend .clearfix;
            li {
                list-style: none;
                padding: 0;
                margin: 0;
                float: left;
                @include rem((
                        padding-left: 10px,
                        margin-right: 10px
                ));
                a {
                    color: $gray-dark;
                    text-decoration: none;
                }
                &:after {
                    @include rem((
                            padding-left: 10px
                    ));
                    content: "|";
                }
                &:last-child {
                    &:after {
                        padding-left: 0;
                        content: " ";
                    }
                    margin-right: 0;
                }
            }
        }
    }
}