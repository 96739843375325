$wheelBox-width: 600px !default;
$wheel-timing: 0.2s;
.bodyBox__wheelBox {
    position: relative;
    @include media-breakpoint-down(md) {
        display: none;
    }
    float: left;
    width: $wheelBox-width + 30px;
    height: $wheelBox-width;
    background-color: $gray-lighter;
    border-top-right-radius:50%;
    border-bottom-right-radius:50%;
    @include media-breakpoint-only(lg) {
        width: $wheelBox-width - 20px;
    }
    .wheelCont {
        &.wheelCont--white {
            background-color: white;
        }
        &.wheelCont--disabled {
            display: none;
        }
        &.wheelCont--enabled {
            display: block;
            z-index:30;
        }
        position: absolute;
        border-radius: 50%;
        top: 20px;
        right: 20px;
        width: 565px;
        height: 565px;
        //overflow: hidden;
        &__rel {
            position: relative;
            width: 100%;
            height: 100%;
            svg {
                .st0{
                    fill:#FFFFFF;
                    &.svg__at {
                        cursor:pointer;
                    }
                }
                .st1{
                    -webkit-transition: all $wheel-timing;
                    -moz-transition: all $wheel-timing;
                    transition: all $wheel-timing;

                    cursor: pointer;
                    fill:#E8EAE2;
                    &:hover {
                        fill:#4975ba;
                    }
                    &.hover {
                        fill:#4975ba;
                    }
                    &.st1--disabled {
                        fill: #e8eae2;
                    }
                }
                .st2{
                    -webkit-transition: all $wheel-timing;
                    -moz-transition: all $wheel-timing;
                    transition: all $wheel-timing;

                    fill:#233689;
                    &.st2--disabled {
                        fill: #cccccc;
                    }
                    &.st2--enable {
                        fill: #7a9f35;
                    }
                }
                .svg__txt {
                    cursor: pointer;
                    &.svg__txt--disabled {
                        fill: #9f9f9f;
                    }
                    &.svg__txt--enable {
                        fill: #ffffff;
                    }
                    &.svg__at--guida-leggera.svg__txt--enable {
                        fill: #325080 !important;
                    }
                }
            }
            &__gif {
                position: absolute;
                top: 36%;
                left: 50%;
                transform: translateY(-50%);
                transform: translateX(-50%);
            }
        }
    }
}
.overlayBox {
    $overlay_dim: 560px;
    width: $overlay_dim;
    height: $overlay_dim;
    &__item {
        position: relative;
        width: $overlay_dim;
        height: $overlay_dim;
        padding: 84px;
        .overlayBox__item__close {
            position: absolute;
            top: 10px;
            right: 90px;
            width: 60px;
            height: 60px;
            background: transparent url( $images-path + '/icon-close-overlay.png') 0 0 no-repeat;
            cursor: pointer;
        }
        &__Vcenter {
            width: $overlay_dim - 84px - 84px;
            text-align: center;
            h3 {
                font-family: $font-family-demiCondensed;
                padding: 0;
                margin: 0;
                height: 32px;
                @include rem ((
                        margin-top: 48px,
                        margin-bottom: 14px,
                        font-size: 28px,
                        line-height: 32px
                ));
                color: $green;
                text-transform: uppercase;
                text-align: center;
            }
            .overlayBox__item__img {
                width: 100px;
                height: 100px;
                margin: 0 auto;
            }
            .overlayBox__item__title {
                font-family: $font-family-demiCondensed;
                margin: 0;
                @include rem ((
                        padding: 0 20px,
                        margin-top: 10px,
                        margin-bottom: 8px,
                        font-size: 24px,
                        line-height: 26px
                ));
                text-transform: uppercase;
                text-align: center;
            }
            .overlayBox__item__descri {
                font-family: $font-family-bookCondensed;
                margin: 0;
                @include rem ((
                        padding: 0 20px,
                        font-size: 18px,
                        line-height: 20px
                ));
                text-align: center;
                ol {
                    @include rem((
                            padding-left: 64px,
                            padding-right: 0
                    ));
                    li {
                        text-align: left;
                    }
                }
            }
        }
    }
}
button.slick-arrow.slick-next, button.slick-arrow.slick-prev {
    height: 30px;
    width: 34px;
    background: transparent none 0 0 no-repeat;
    z-index:99;
}
button.slick-arrow.slick-prev {
    left: 30px;
    background-image: url( $images-path + '/ruota/overlay/arrowSX_off.png');
    &:hover {
        background-image: url( $images-path + '/ruota/overlay/arrowSX_on.png');
    }
}
button.slick-arrow.slick-next {
    right: 30px;
    background-image: url( $images-path + '/ruota/overlay/arrowDX_off.png');
    &:hover {
        background-image: url( $images-path + '/ruota/overlay/arrowDX_on.png');
    }
}
button.slick-arrow.slick-prev:before, button.slick-arrow.slick-next:before {
    content:"";
    font-size: 0;
}