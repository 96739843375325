$rem-baseline: 10px !default;
$rem-fallback: true !default;
$rem-px-only: false !default;

// list-separator polyfill by Hugo Giraudel (https://sass-compatibility.github.io/#list_separator_function)
@function rem-separator($list) {
  @if function-exists("list-separator") == true {
    @return list-separator($list);
  }

  $test-list: ();
  @each $item in $list {
    $test-list: append($test-list, $item, space);
  }

  @return if($test-list == $list, space, comma);
}

@mixin rem-baseline($zoom: 100%) {
  font-size: $zoom / 16px * $rem-baseline;
}

@function rem-convert($to, $values...) {
  $result: ();
  $separator: rem-separator($values);

  @each $value in $values {
    @if type-of($value) == "number" and unit($value) == "rem" and $to == "px" {
      $result: append($result, $value / 1rem * $rem-baseline, $separator);
    } @else if type-of($value) == "number" and unit($value) == "px" and $to == "rem" {
      $result: append($result, $value / ($rem-baseline / 1rem), $separator);
    } @else if type-of($value) == "list" {
      $result: append($result, rem-convert($to, $value...), $separator);
    } @else {
      $result: append($result, $value, $separator);
    }
  }

  @return if(length($result) == 1, nth($result, 1), $result);
}

@function rem($values...) {
  @if $rem-px-only {
    @return rem-convert(px, $values...);
  } @else {
    @return rem-convert(rem, $values...);
  }
}

@mixin rem($properties, $values...) {
  @if type-of($properties) == "map" {
    @each $property in map-keys($properties) {
      @include rem($property, map-get($properties, $property));
    }
  } @else {
    @each $property in $properties {
      @if $rem-fallback or $rem-px-only {
        #{$property}: rem-convert(px, $values...);
      }
      @if not $rem-px-only {
        #{$property}: rem-convert(rem, $values...);
      }
    }
  }
}
