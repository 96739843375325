.cookiesBanner {
    display: none;
    background-color: #d9d9d9;
    font-family: $font-family-bookCondensed;
    font-size: 16px;
    line-height: 20px;
    color: #646464;
    p {
        margin: 1.25rem 0;
    }
    .cookieBannerClose {
        @include media-breakpoint-down(md) {
            display: block;
            margin: 0 auto 1rem auto;
        }
        .btn-cookies {
            display: block;
            padding: 0 1rem;
            font-family: $font-family-bookCondensed;
            font-size: 1rem;
            line-height: 2.5rem;
            background-color: $blue;
            color: white;
            text-transform: uppercase;
            text-decoration: none;
            text-align: center;
            &:hover {
                background-color: darken($blue, 5%);
                color: white;
                text-decoration: none;
            }
            @include media-breakpoint-down(md) {
                width: 50%;
                margin: 0 auto;
            }
        }
    }
}