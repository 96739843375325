@include generate-margin-padding-y-class();
@include generate-margin-padding-x-class();
@include generate-margin-padding-class('top');
@include generate-margin-padding-class('bottom');
@include generate-margin-padding-class('left');
@include generate-margin-padding-class('right');

.container-fluid {
    @include media-breakpoint-down(md) {
        padding-left: 0;
        padding-right: 0;
    }
}