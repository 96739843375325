.header {
    height: 80px;
    position:relative;
    .logo {
        @include vertical-align();
        left:15px;
        @include media-breakpoint-down(xs) {
            width: 75%;
        }
    }
    .social {
        @include vertical-align();
        right: 15px;
        width: 30px;
        height: 30px;
        overflow: hidden;
        transition: width 0.3s linear 0.3s;
        &.social--open {
            width: 110px;
        }
        .social__index {
            float:right;
            width: 30px;
            height: 30px;
        }
        .social__tw, .social__fb {
            float:right;
            width: 40px;
            height: 30px;
        }
    }
}