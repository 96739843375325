/* BLADE: source/_partials/landing/base/header.blade.php */
/* SCSS:  source/_assets/sass/partials/modules/landing/_corner-ribbon.scss */

.ribbon-wrapper-green {
    width: 150px;
    height: 160px;
    overflow: hidden;
    position: absolute;
    top: -2px;
    right: -2px;
}

.ribbon-green {
    font-family: $font-family-demiCondensed;
    line-height: 22px;
    text-transform: uppercase;
    color: white;
    text-align: center;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    position: relative;
    @include rem(padding,20px 5px 6px);
    left: -11px;
    top: 11px;
    width: 230px;
    background-color: #7bbf38;
    -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    font-size: 18px;
    &:before {
        content: "";
        border-top: 3px solid #6e8900;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        position: absolute;
        bottom: -3px;
        left: 0;
    }
    &:after {
        content: "";
        border-top: 3px solid #6e8900;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        position: absolute;
        bottom: -3px;
        right: 0;
    }
    span {
        font-size: 19px;
    }
}